import React, { useEffect, useState } from 'react';

import { 
  Flex, 
  Box, 
  Heading, 
  Spacer,
  IconButton, 
  Button, 
  Link, 
  Icon, 
  useColorMode,
  HStack,
 } from "@chakra-ui/react";
import { Link as GatsbyLink, navigate } from "gatsby"
import { FaDice, FaLightbulb } from "react-icons/fa"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import ChangeLanguage from './ChangeLanguage';
import {useStaticQuery, graphql} from 'gatsby';

import { useLazyQuery, gql } from '@apollo/client';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const QUERY_TOTAL_POSTS = gql`
  query QueryTotalPosts($locale: String!) {
    blogPostCollection(locale: $locale){
      total
    }
  }
`;

const QUERY_RANDOM_POST = gql`
  query RandomPost($locale: String! $skip: Int!){
    blogPostCollection(skip: $skip, limit: 1, locale: $locale){
      items{
        slug
      }
    }
  }
`;

const Header = () => {
  const {t} = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode()
  const context = React.useContext(I18nextContext);

  const [getTotalPosts, { error, loading: loadingTotalPosts, data: totalPosts }] = useLazyQuery(QUERY_TOTAL_POSTS);
  const [getRandomPostSlug, { loading: loadingRandomPost, data: randomPost }] = useLazyQuery(QUERY_RANDOM_POST);

  useEffect(() => {
    if(totalPosts){
      const { total } = totalPosts.blogPostCollection;
      getRandomPostSlug({
        variables: {
          locale: context.language,
          skip: getRandomInt(0, total)
        }
      })
    }
  }, [totalPosts])

  useEffect(() => {
    if(randomPost){
      const { slug } = randomPost.blogPostCollection.items[0];
      navigate(`/${context.language}/${slug}`, {
        state: { 
          fromSerendipityButton: true
        },
      })
    }
  }, [randomPost])

  
  const handleClickButton = () => {
    getTotalPosts({
      variables: {
        locale: context.language
      }
    });
  }

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
    );

  return (
    <>  
    <Flex h="200px" maxH="100px" alignItems="center">
      <Box>
        <Heading as={GatsbyLink} to={`/${context.language}`} size="md">Serenpit</Heading>
      </Box>
      <Spacer />
      <HStack spacing={["2", "2", "4"]} alignItems="center">
        <ChangeLanguage/>
        <Button 
          leftIcon={<Icon as={FaLightbulb} />} 
          variant="ghost"
          display={["none", "none", "block"]}
          onClick={toggleColorMode} 
        >
          {colorMode === "light" ? t('colorMode.dark') : t('colorMode.light')} 
        </Button>
        <IconButton 
          icon={<Icon as={FaLightbulb} />} 
          variant="ghost"
          display={["block", "block", "none"]}
          aria-label={colorMode === "light" ? t('colorMode.dark') : t('colorMode.light')}
          onClick={toggleColorMode} 
        />
        <IconButton 
          icon={<Icon as={FaDice} />} 
          colorScheme="teal"
          display={["block", "block", "none"]}
          onClick={handleClickButton}
          aria-label={t('random')}
        />
        <Button 
          leftIcon={<Icon as={FaDice} />} 
          colorScheme="teal"
          display={["none", "none", "block"]}
          onClick={handleClickButton}
          isLoading={loadingTotalPosts || loadingRandomPost}
        >
          {t('random')}
        </Button>
      </HStack>
      
    </Flex>
    </>
  )
}

export default Header;