import React from "react"
import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';
import ReactCountryFlag from "react-country-flag"
import {
  Button,
  Text,
  IconButton
} from '@chakra-ui/react';

const languageName = {
  ["pt"]: "pt",
  ["en"]: "en",
}

const languageClick = (e, language, changeLanguage) => {
  e.preventDefault()
  if (language == 'pt') {
    changeLanguage('en', '/')
  } else {
    changeLanguage('pt', '/')
  }
}

const ChangeLanguage = () => {
  const { languages, changeLanguage } = useI18next();
  const context = React.useContext(I18nextContext);

  return (
    <div>
      {context.language == "en" &&
        <IconButton
          variant="ghost"
          onClick={e => languageClick(e, context.language, changeLanguage)}
          aria-label="Mudar língua do site para português" icon={<ReactCountryFlag countryCode="US" />}
        />
      }
      {context.language == "pt" &&
        <IconButton
          variant="ghost"
          onClick={e => languageClick(e, context.language, changeLanguage)}
          aria-label="Change site language to english" icon={<ReactCountryFlag countryCode="BR" />}
        />
      }
    </div>
  )
}

export default ChangeLanguage